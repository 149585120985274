<template>
  <ContentBlock>
    <div class="top" style="padding: 12px 12px 0 20px;" v-if="isShowHead">
      <b>证件/材料</b> <span style="font-family: PingFangSC-Regular;font-size: 12px;color: #5F6A7A;letter-spacing: 0; margin-left: 15px;">医疗器械经营/生产企业请维护相关资质</span>
    </div>
    <div class="materialContent">
      <div class="left">
        <ul class="navContainer">
          <li v-for="material in MaterialLists" :key="material.label" :class="{'active': activeName === material.value, 'required': material.required}" @click="handleClick(material.value, activeName)">
            <span>{{ material.label }}</span> <i class="el-icon-info" v-if="material.showError"  style="color:red;font-size: 14px;margin-top:2px;"></i></li>
        </ul>
      </div>
      <div class="right">
        <BusinessLicence
          v-show="activeName === 'BusinessLicence'"
          ref="JZBusinessLicence"
          :activeName="activeName"
          :id="$route.query.id"
          :certificateLists="certificateLists"
          :isDisabled="isDisabled"
          :isShowHead="isShowHead"
          @updateMaterial="updateMaterial">
        </BusinessLicence>
        <AccountInformation
          v-show="activeName === 'AccountInformation'"
          ref="JZAccountInformation"
          :activeName="activeName"
          :id="$route.query.id"
          :certificateLists="certificateLists"
          :isDisabled="isDisabled"
          :isShowHead="isShowHead"
          @updateMaterial="updateMaterial">
        </AccountInformation>
        <MedicalDeviceLicence
          v-show="activeName === 'MedicalDeviceLicence'"
          :id="$route.query.id"
          :activeName="activeName"
          ref="JZMedicalDeviceLicence"
          :certificateLists="certificateLists"
          :isDisabled="isDisabled"
          :isShowHead="isShowHead"
          @updateMaterial="updateMaterial">
        </MedicalDeviceLicence>
        <BusinessRecordCertificate
          v-show="activeName === 'BusinessRecordCertificate'"
          :id="$route.query.id"
          :activeName="activeName"
          :certificateLists="certificateLists"
          :isDisabled="isDisabled"
          :isShowHead="isShowHead"
          ref="JZBusinessRecordCertificate"
          @updateMaterial="updateMaterial">
        </BusinessRecordCertificate>
        <ManufacturingLicence
          v-show="activeName === 'ManufacturingLicence'"
          :id="$route.query.id"
          :activeName="activeName"
          ref="JZManufacturingLicence"
          :certificateLists="certificateLists"
          :isDisabled="isDisabled"
          :isShowHead="isShowHead"
          @updateMaterial="updateMaterial">
        </ManufacturingLicence>
        <ProductionRecordCertificate
          v-show="activeName === 'ProductionRecordCertificate'"
          :id="$route.query.id"
          :activeName="activeName"
          ref="JZProductionRecordCertificate"
          :certificateLists="certificateLists"
          :isDisabled="isDisabled"
          :isShowHead="isShowHead"
          @updateMaterial="updateMaterial">
        </ProductionRecordCertificate>
        <QualityAssuranceAgreement
          v-show="activeName === 'QualityAssuranceAgreement'"
          :id="$route.query.id"
          :activeName="activeName"
          ref="JZQualityAssuranceAgreement"
          :certificateLists="certificateLists"
          :isDisabled="isDisabled"
          :isShowHead="isShowHead"
          @updateMaterial="updateMaterial">
        </QualityAssuranceAgreement>
        <DelegationAgreement
          v-show="activeName === 'DelegationAgreement'"
          :id="$route.query.id"
          :activeName="activeName"
          ref="JZDelegationAgreement"
          :certificateLists="certificateLists"
          :isDisabled="isDisabled"
          :isShowHead="isShowHead"
          @updateMaterial="updateMaterial">
        </DelegationAgreement>
        <TrusteeIdentityCard
          v-show="activeName === 'TrusteeIdentityCard'"
          :id="$route.query.id"
          :activeName="activeName"
          ref="JZTrusteeIdentityCard"
          :certificateLists="certificateLists"
          :isDisabled="isDisabled"
          :isShowHead="isShowHead"
          @updateMaterial="updateMaterial">
        </TrusteeIdentityCard>
        <AccompanyingSheetTemplate
          v-show="activeName === 'AccompanyingSheetTemplate'"
          ref="JZAccompanyingSheetTemplate"
          :activeName="activeName"
          :id="$route.query.id"
          :certificateLists="certificateLists"
          :isDisabled="isDisabled"
          :isShowHead="isShowHead"
          @updateMaterial="updateMaterial">
        </AccompanyingSheetTemplate>
      </div>
    </div>
  </ContentBlock>
  <!-- <ContentBlock v-else>
    <el-empty description="暂未维护数据" :image="require('@/assets/empty.png')"></el-empty>
  </ContentBlock> -->
</template>

<script>
import {updateTenantInformation} from '@/api/systemSetting';
export default {
  name: '',
  props: {
    isShowHead: {
      type: Boolean,
      default () {
        return false;
      },
    },
    currentInfo: {
      type: Object,
      default () {
        return {};
      },
    },
    isDisabled: {
      type: Boolean,
      default () {
        return false;
      },
    },
  },
  components: {
    BusinessLicence: ()=>import('./component/BusinessLicence.vue'),
    AccountInformation: ()=>import('./component/AccountInformation.vue'),
    MedicalDeviceLicence: ()=>import('./component/MedicalDeviceLicence.vue'),
    BusinessRecordCertificate: ()=>import('./component/BusinessRecordCertificate.vue'),
    ManufacturingLicence: ()=>import('./component/ManufacturingLicence.vue'),
    ProductionRecordCertificate: ()=>import('./component/ProductionRecordCertificate.vue'),
    QualityAssuranceAgreement: ()=>import('./component/QualityAssuranceAgreement.vue'),
    DelegationAgreement: ()=>import('./component/DelegationAgreement.vue'),
    TrusteeIdentityCard: ()=>import('./component/TrusteeIdentityCard.vue'),
    AccompanyingSheetTemplate: ()=>import('./component/AccompanyingSheetTemplate.vue'),
  },
  watch: {
    currentInfo: {
      handler (val) {
        this.tempCertificateLists = [];
        if (val && val.tenantCertificateInfoRespDTOs && val.tenantCertificateInfoRespDTOs.length) {
          this.certificateLists = (val.tenantCertificateInfoRespDTOs || []).filter(i=>i.type);
        } else {
          this.certificateLists = [];
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    formArr () {
      // 'JZMedicalDeviceLicence', 'JZBusinessRecordCertificate', 'JZManufacturingLicence', 'JZProductionRecordCertificate', 'JZQualityAssuranceAgreement', 'JZDelegationAgreement', 'JZTrusteeIdentityCard', 'JZAccompanyingSheetTemplate'
      return ['JZBusinessLicence', 'JZAccountInformation'];
    },
    MaterialLists () {
      let errorFormLists = this.errorFormLists;
      return [
        {label: '营业执照', value: 'BusinessLicence', type: '6', required: this.isShowHead, showError: errorFormLists.includes('JZBusinessLicence')},
        {label: '开户信息', value: 'AccountInformation', type: '14', required: this.isShowHead, showError: errorFormLists.includes('JZAccountInformation')},
        {label: '医疗器械经营许可证', value: 'MedicalDeviceLicence', type: '7'},
        {label: '医疗器械经营备案凭证', value: 'BusinessRecordCertificate', type: '8'},
        {label: '医疗器械生产许可证', value: 'ManufacturingLicence', type: '9'},
        {label: '医疗器械生产备案凭证', value: 'ProductionRecordCertificate'},
        {label: '质量保证协议', value: 'QualityAssuranceAgreement', type: '10'},
        {label: '授权委托协议', value: 'DelegationAgreement', type: '11'},
        {label: '委托人身份证', value: 'TrusteeIdentityCard', type: '12'},
        {label: '随货同行单模板', value: 'AccompanyingSheetTemplate', type: '13'},
      ];
    },
  },
  methods: {
    handleClick (value, preActiveName) {
      if (value !== preActiveName) {
        if (this.$route.name === 'CorporateCertification') {
          // this.handleUpdateMaterial(preActiveName);
        } else {
          this.handleUpdateCancel(preActiveName);
        }
        this.activeName = value;
      }
    },
    handleUpdateCancel (preActiveName) {
      if (preActiveName === 'BusinessLicence') {
        this.$refs.JZBusinessLicence.handleCancel();
      } else if (preActiveName === 'AccountInformation') {
        this.$refs.JZAccountInformation.handleCancel();
      } else if (preActiveName === 'MedicalDeviceLicence') {
        this.$refs.JZMedicalDeviceLicence.handleCancel();
      } else if (preActiveName === 'BusinessRecordCertificate') {
        this.$refs.JZBusinessRecordCertificate.handleCancel();
      } else if (preActiveName === 'ManufacturingLicence') {
        this.$refs.JZManufacturingLicence.handleCancel();
      } else if (preActiveName === 'ProductionRecordCertificate') {
        this.$refs.JZProductionRecordCertificate.handleCancel();
      } else if (preActiveName === 'QualityAssuranceAgreement') {
        this.$refs.JZQualityAssuranceAgreement.handleCancel();
      } else if (preActiveName === 'DelegationAgreement') {
        this.$refs.JZDelegationAgreement.handleCancel();
      } else if (preActiveName === 'TrusteeIdentityCard') {
        this.$refs.JZTrusteeIdentityCard.handleCancel();
      } else if (preActiveName === 'AccompanyingSheetTemplate') {
        this.$refs.JZAccompanyingSheetTemplate.handleCancel();
      }
    },
    handleUpdateMaterial (preActiveName) {
      if (preActiveName === 'BusinessLicence') {
        this.$refs.JZBusinessLicence.handleUpdate();
      } else if (preActiveName === 'AccountInformation') {
        this.$refs.JZAccountInformation.handleUpdate();
      } else if (preActiveName === 'MedicalDeviceLicence') {
        this.$refs.JZMedicalDeviceLicence.handleUpdate();
      } else if (preActiveName === 'BusinessRecordCertificate') {
        this.$refs.JZBusinessRecordCertificate.handleUpdate();
      } else if (preActiveName === 'ManufacturingLicence') {
        this.$refs.JZManufacturingLicence.handleUpdate();
      } else if (preActiveName === 'ProductionRecordCertificate') {
        this.$refs.JZProductionRecordCertificate.handleUpdate();
      } else if (preActiveName === 'QualityAssuranceAgreement') {
        this.$refs.JZQualityAssuranceAgreement.handleUpdate();
      } else if (preActiveName === 'DelegationAgreement') {
        this.$refs.JZDelegationAgreement.handleUpdate();
      } else if (preActiveName === 'TrusteeIdentityCard') {
        this.$refs.JZTrusteeIdentityCard.handleUpdate();
      } else if (preActiveName === 'AccompanyingSheetTemplate') {
        this.$refs.JZAccompanyingSheetTemplate.handleUpdate();
      }
    },
    updateMaterial (form, type) {
      let certificateLists = JSON.parse(JSON.stringify(this.certificateLists));
      let typeLists = certificateLists.map(i => i.type);
      if (typeLists.includes(type)) {
        certificateLists = certificateLists.map(i=>{
          if (i.type === type) {
            return form;
          }
          return i;
        });
      } else {
        certificateLists.push({
          ...form,
          type,
        });
      }
      if (this.$route.name === 'CorporateCertification') {
        this.$emit('updateData', certificateLists, 'tenantCertificateInfoRespDTOs');
      } else {
        let currentParams = {
          dataType: '1',
          tenantCertificateInfoReqDTOs: certificateLists,
        };
        updateTenantInformation(currentParams).then(() => {
          this.$emit('updatePage');
        });
      }
    },
    checkForm (list) {
      let resultLists = [];
      let results = list.map(name => {
        return new Promise((resolve, reject) => {
          let formObj = null;
          formObj = this.$refs[name].$refs.JZForm;
          formObj.validate((valid, object) => {
            if (valid) {
              resolve();
            } else {
              resultLists.push({
                ...object,
                name,
              });
              reject(new Error('验证失败'));
              this.$nextTick(() => {
                var isError = document.querySelectorAll('.is-error');
                isError[0].querySelector('input').focus();
              });
            }
          });
        });
      });
      return Promise.all(results).catch(() => {
        return Promise.reject(resultLists);
      });
    },
    async handleValidateForm () {
      this.errorFormLists = [];
      return await this.checkForm(this.formArr).then((valid) => {
        if (valid) {
          return true;
        }
      }).catch((err)=>{
        this.errorFormLists = (err || []).map(i => i.name);
        if (this.errorFormLists && this.errorFormLists.length) {
          this.$message.warning('请补充完成必填信息');
          return false;
        }
        return false;
      });
    },
    getTenantCertificateInfoRespDTOs () {
      this.tempCertificateLists = JSON.parse(JSON.stringify(this.certificateLists));
      let form = this.$refs.JZBusinessLicence.form;
      this.getCertificateLists(form, '6');
      form = this.$refs.JZAccountInformation.form;
      this.getCertificateLists(form, '14');
      form = this.$refs.JZMedicalDeviceLicence.form;
      this.getCertificateLists(form, '7');
      form = this.$refs.JZBusinessRecordCertificate.form;
      this.getCertificateLists(form, '8');
      form = this.$refs.JZManufacturingLicence.form;
      this.getCertificateLists(form, '9');
      form = this.$refs.JZProductionRecordCertificate.form;
      this.getCertificateLists(form, '18');
      form = this.$refs.JZQualityAssuranceAgreement.form;
      this.getCertificateLists(form, '10');
      form = this.$refs.JZDelegationAgreement.form;
      this.getCertificateLists(form, '11');
      form = this.$refs.JZTrusteeIdentityCard.form;
      this.getCertificateLists(form, '12');
      form = this.$refs.JZAccompanyingSheetTemplate.form;
      this.getCertificateLists(form, '13');
      this.$emit('updateData', this.tempCertificateLists, 'tenantCertificateInfoRespDTOs');
      return this.tempCertificateLists;
    },
    getCertificateLists (form, type) {
      let tenantFileReqDTOs = (form.tenantFileReqDTOs || []).map(i=>({...i, type}));
      form.tenantFileReqDTOs = tenantFileReqDTOs || [];
      let tempCertificateLists = this.tempCertificateLists;
      let typeLists = tempCertificateLists.map(i => i.type);
      if (typeLists.includes(type)) {
        tempCertificateLists = tempCertificateLists.map(i=>{
          if (i.type === type) {
            return {...form, type};
          }
          return i;
        });
      } else {
        tempCertificateLists.push({...form, type});
      }
      this.tempCertificateLists = tempCertificateLists;
    },
  },
  data () {
    return {
      activeName: 'BusinessLicence',
      errorFormLists: [],
      currentType: '6',
      certificateLists: [],
      tempCertificateLists: [],
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
.content_block{
  padding: 0 20px 20px 0;
  min-height: calc(100vh - 420px);
}
.navContainer{
  list-style: none;
  width: 180px;
  margin-bottom: 0;

  margin-right: 10px;
  li{
    height:40px;
    line-height: 40px;
    margin-bottom: 0;
    padding-left: 20px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #5F6A7A;
    letter-spacing: 0;
    text-align: left;
    line-height: 20px;
    padding: 10px 20px 10px 20px;
    cursor: pointer;
    &.active{
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #237FFA;
      letter-spacing: 0;
      text-align: left;
      line-height: 20px;
      background: #E2F3FE;
      position: relative;
      &::after{
        content: '';
        width:2px;
        height:20px;
        position: absolute;
        left: 0;
        top: 10px;
        background: #237FFA;
      }
    }
    &.required{
      position: relative;
      display: flex;
      span{
        flex: 1;
      }
      &::before{
        content: '*';
        width:8px;
        height:8px;
        position: absolute;
        left: 10px;
        top: 12px;
        color: #FF5959;
      }
    }
  }
}
.materialContent{
  display: flex;
  padding-top: 8px;
  padding-right: 10px;
  .right{
    flex: 1;
    background: #FFFFFF;
    border: 1px solid #DFE2E8;
    border-radius: 4px;
  }
}
</style>
